import React, {
  useState,
  useRef,
  useImperativeHandle,
  forwardRef,
} from "react";
import "styles/DestinationsTop.css";
import hoteles from "assets/HotelesNacionales.json";
import { NavLink } from "react-router-dom";

const DestinationsTop = forwardRef((props, ref) => {
  const [pagination, setPagination] = useState(0);
  const [search, setSearch] = useState("");
  const containerRef = useRef(null);

  const scrollToTop = () => {
    if (containerRef.current) {
      containerRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useImperativeHandle(ref, () => ({
    scrollToTop: scrollToTop,
  }));

  const filterCoupons = () => {
    if (search.length === 0)
      return hoteles.HotelesNacionales.slice(pagination, pagination + 6);
    // Busqueda activa
    const filtered = hoteles.HotelesNacionales.filter(
      (hotel) =>
        hotel.Destino.toLowerCase().includes(search.toLowerCase()) ||
        hotel.Hotel.toLowerCase().includes(search.toLowerCase()) ||
        hotel.categoria.toLowerCase().includes(search.toLowerCase())
    );
    return filtered.slice(pagination, pagination + 6);
  };

  const next = () => {
    if (pagination + 6 < hoteles.HotelesNacionales.length)
      setPagination(pagination + 6);
  };
  const back = () => {
    if (pagination > 0) setPagination(pagination - 6);
  };

  const onSearchChange = ({ target }) => {
    setPagination(0);
    setSearch(target.value);
  };

  return (
    <div
      ref={containerRef}
      className="container"
      id="destinos"
      data-aos="fade-in"
    >
      <div className="line-top"></div>
      <h3 className="title-top">Hoteles top</h3>

      <div className="col-lg-4 col-md-12 col-sm-12">
        <div className="form">
          <label>
            <input
              className="input"
              type="text"
              required=""
              placeholder="Buscar..."
              id="search"
              value={search}
              onChange={onSearchChange}
            />
            <div className="fancy-bg"></div>
            <div className="search">
              <svg viewBox="0 0 24 24" aria-hidden="true">
                <g>
                  <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
                </g>
              </svg>
            </div>
            {search.length > 0 ? (
              <button
                className="close-btn"
                type="reset"
                onClick={() => setSearch("")}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"></path>
                </svg>
              </button>
            ) : (
              ""
            )}
          </label>
        </div>
      </div>

      <div className="hoteles-grid ">
        {filterCoupons().length > 0 ? (
          <>
            {filterCoupons().map((hotel, index) => (
              <NavLink
                to={`/${hotel.Destino}/${hotel.Hotel}`}
                key={hotel.Hotel}
                data-aos="zoom-in-up"
              >
                <div className="image-container">
                  <img
                    loading="lazy"
                    src={hotel.miniatura}
                    alt={hotel.Hotel}
                    className="b-10"
                  />
                  <div className="texto-encima">{hotel.Destino}</div>
                </div>
                <p className="hotel_title">{hotel.Hotel}</p>
              </NavLink>
            ))}
          </>
        ) : (
          <h3 className="title-top">Sin resultados</h3>
        )}
      </div>

      <div className="container-buttons">
        <button
          className="arrow"
          onClick={back}
          disabled={pagination > 0 ? false : true}
        >
          <i className="fa-sharp fa-solid fa-arrow-left"></i>
        </button>
        <button
          className="arrow"
          onClick={next}
          disabled={
            pagination + 6 < hoteles.HotelesNacionales.length ? false : true
          }
        >
          <i className="fa-sharp fa-solid fa-arrow-right"></i>
        </button>
      </div>
    </div>
  );
});

export default DestinationsTop;
