import React from "react";
import "styles/about.css";
import useSendEventGA from "hooks/useSendEventGA";
const About = () => {
  const SendEvent = (e) => {
    e.preventDefault();
    window.location.href = "tel:+6015520100";
    useSendEventGA("Contáctanos", "Click en boton mi Contáctanos ");
  };

  return (
    <article className="container-fluid">
      <div className="row">
        <div className="col-lg-5 col-md-12 col-sm-12 add-Scale">
          <div className="about-padding">
            <h1 className="title-about">¿Quiénes somos?</h1>
            <p className="line-red"></p>
            <h6 className="subtitulo mt-2">Agencia de viajes</h6>
            <p className="p">
              Grupo Alianza Colombia es una empresa en la industria turística
              colombiana, que cuenta con operación en varias ciudades del país
              desde hace más de 3 años, ofreciendo distintos servicios en
              turismo, recreación, descanso, diversión, cultura, gastronomía,
              entre otros, con la mejor relación del costo/ beneficio.
            </p>
            <div className="mt-2 p">
              La membresía ALIANZA, está diseñada para clientes que quieren
              recibir un servicio exclusivo y descuentos especiales en tarifas.
            </div>

            <button className="btn-contactanos mt-4 mb-5" onClick={SendEvent}>
              Contáctanos
            </button>
          </div>
        </div>
        <div className="col-lg-7 col-md-12 col-sm-12 add-Scale">
          <img
            loading="lazy"
            src="/images/about.jpg"
            alt="img"
            className="add-Scale img-fluid"
            width={"100%"}
          />
        </div>
        <div className="container-about">
          <div className="row">
            <div
              className="col-lg-6 col-md-12 col-sm-12 add-Scale"
              data-aos="fade-right"
            >
              <h5 className="second-text text-center">Misión</h5>
              <p className=" p">
                La empresa tiene como misión prestar un servicio exclusivo y de
                calidad a los clientes interesados en viajar a distintos lugares
                del mundo, permitiéndoles escoger el plan turístico que más se
                ajuste a sus necesidades, para que vivan momentos mágicos en
                familia.
              </p>
            </div>
            <div
              className="col-lg-6 col-md-12 col-sm-12 add-Scale"
              data-aos="fade-left"
            >
              <h5 className="second-text text-center">Visión</h5>
              <p className=" p">
                Ser una empresa reconocida por el sector turístico, como la
                mejor y más confiable compañía para planear viajes a nivel
                nacional e internacional, gracias a nuestro alto compromiso en
                crear programas vacacionales con un amplio catálogo de lugares
                destacados por su diversidad cultural, ubicados en entornos
                amigables con el medio ambiente y enfocados en el turismo
                sostenible.
              </p>
            </div>
          </div>
          <div className="centrar mt-5">
            <div
              className="col-lg-4 col-md-12 col-sm-12 add-Scale"
              data-aos="zoom-in"
            >
              <h5 className="second-text">Valores corporativos</h5>
              <p className="line-red"></p>
              <ol className="p mt-3">
                <li>Servicio</li>
                <li>Excelencia</li>
                <li>Compromiso</li>
                <li>Integridad</li>
                <li>Respeto</li>
                <li>Confidencialidad</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
};

export default About;
