import React from "react";
import "swiper/swiper.min.css";
import useSendEventGA from "../hooks/useSendEventGA";

const Banners = () => {
  const SendEvent = (e) => {
    e.preventDefault();
    window.open("https://vip.grupoalianzacolombia.com", "_blank");
    useSendEventGA("Priority", "Click en boton ¡Reserva ya! (Banners)");
  };
  return (
    <div className="row">
      <div className="col-5">
        <div className="container-banner" data-aos="fade-right">
          <h1 className="title-banner">¡Vacaciones a tu medida! </h1>
          <p className="line-red"></p>
          <p>
            Disfruta de paisajes paradisíacos, playas de ensueño y actividades
            extremas para hacer en tu próximo viaje en pareja o familiar.
          </p>
          <button className="btn-viajar" onClick={SendEvent}>
            ¡Reserva ya!
          </button>
        </div>
      </div>
      <div className="col-7">
        <video
          data-aos="zoom-in"
          className="video-custom"
          src="/video/BannerVideo.mp4"
          autoPlay
          muted
          onEnded={(e) => {
            e.target.currentTime = 0;
            e.target.play();
          }}
          controls={false}
          playsInline
          allow="fullscreen"
        ></video>
      </div>
    </div>
  );
};

export default Banners;
