import React, { useState } from "react";
import "styles/contact.css";
import sedes from "assets/Points.json";

const INITIAL_STATE = {
  nombre: "",
  correo: "",
  asunto: "",
  mensaje: "",
};

const Contact = () => {
  const [info_form, setInfo_form] = useState(INITIAL_STATE); // Estado para el valor del input

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInfo_form((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(info_form);
  };

  return (
    <>
      <div className="contenedor-relative">
        <img
          loading="lazy"
          src="/images/Contactenos.jpg"
          alt=""
          width={"100%"}
        />
        <h1 className="title-section">CONTÁCTANOS</h1>
      </div>
      <div className="container-fluid pt-4">
        <p className="text-center">
          ¡Hola! ¿Necesitas ponerte en contacto con nosotros? ¡Estamos aquí para
          ayudarte!{" "}
        </p>
        <p className="text-center pb-4">
          {" "}
          Si tienes preguntas sobre nuestros servicios, puedes enviar un correo
          electrónico a nuestro equipo de atención al cliente a través de
          nuestro formulario o línea telefónica.
        </p>
        <div className="centrar">
          <div className="container-contact">
            <div className="row">
              <div className="contenedor-form">
                <h3 className="title-policy fw-bold">Escríbenos</h3>
                <div className="login-box">
                  <form autoComplete="new-password" onSubmit={handleSubmit}>
                    <div className="user-box">
                      <input
                        required
                        name="nombre"
                        value={info_form.nombre}
                        onChange={handleChange}
                        type="text"
                        autoComplete="new-password"
                      />
                      <label>Nombre</label>
                    </div>
                    <div className="user-box">
                      <input
                        required
                        name="correo"
                        value={info_form.correo}
                        onChange={handleChange}
                        type="email"
                        autoComplete="new-password"
                      />
                      <label>Correo</label>
                    </div>
                    <div className="user-box">
                      <input
                        required
                        name="asunto"
                        value={info_form.asunto}
                        onChange={handleChange}
                        type="text"
                        autoComplete="new-password"
                      />
                      <label>Asunto</label>
                    </div>
                    <div className="user-box">
                      <textarea
                        required
                        name="mensaje"
                        value={info_form.mensaje}
                        onChange={handleChange}
                        type="text"
                        autoComplete="new-password"
                        className="textArea"
                        placeholder="Mensaje"
                      ></textarea>
                    </div>
                    <label htmlFor="termino" className="terminos">
                      <input id="termino" type="checkbox" required />
                      Con el diligenciamiento de mis datos en el presente
                      formulario acepto de forma previa, expresa e informada la
                      Autorización para el tratamiento de datos personales. ver
                      Tratamiento de datos personales
                    </label>
                    <button className="btn-login">Enviar</button>
                  </form>
                </div>
              </div>
              <div className="chanel">
                <h3 className="title-policy fw-bold">Canales de atención</h3>
                <a href="tel:+6015520100" className="color-text">
                  <i className="fa-solid fa-phone-volume color-text"></i>
                  (601)5520100
                </a>
                <a href="tel:+6012205214" className="color-text">
                  <i className="fa-solid fa-phone-volume color-text"></i>
                  (601)2205214
                </a>
                <a
                  href="mailto:sac@grupoalianzacolombia.com"
                  className="color-text"
                >
                  <i className="fas fa-envelope color-text"></i>
                  sac@grupoalianzacolombia.com
                </a>
                <a
                  href="mailto:reservas@grupoalianzacolombia.com"
                  className="color-text"
                >
                  <i className="fas fa-envelope color-text"></i>
                  reservas@grupoalianzacolombia.com
                </a>

                <h3 className="title-policy fw-bold">Redes Sociales</h3>
                <div>
                  <a
                    href="https://www.facebook.com/Grupoalianzacolombia"
                    target="_blank"
                    rel="noreferrer"
                    className="round-gray"
                  >
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  <a
                    href="https://www.instagram.com/grupoalianzacolombia"
                    target="_blank"
                    rel="noreferrer"
                    className="round-gray"
                  >
                    <i className="fa-brands fa-instagram"></i>
                  </a>
                  <a
                    href="https://www.tiktok.com/@grupoalianzacolombia?lang=es"
                    target="_blank"
                    rel="noreferrer"
                    className="round-gray"
                  >
                    <i className="fa-brands fa-tiktok"></i>
                  </a>
                  <a
                    href="https://www.youtube.com/@grupoalianzacolombia"
                    target="_blank"
                    rel="noreferrer"
                    className="round-gray"
                  >
                    <i className="fa-brands fa-youtube"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="mapamundi">
          <h3 className="title-policy fw-bold text-center ">
            Nuestros puntos de venta
          </h3>
          <div className="row">
            {sedes.map((point, index) => (
              <div className="col-3 puntos" key={index}>
                <p>{point?.sede}</p>
                <p className="fw-bold">{point?.ubicacion}</p>
                <p>{point?.descripcion.toLowerCase()}</p>
                <p>{point?.direccion.toLowerCase()}</p>
              </div>
            ))}
          </div>
        </section>

        <section className="centrar mb-5">
          <div className="subscription">
            <h3 className="title-policy fw-bold text-center">SUSCRIBIRSE</h3>
            <p className="text-center">
              Recibe actualizaciones y novedades en cualquier lugar.
            </p>
            <form action="">
              <div className="login-box mt-2">
                <div className="user-box">
                  <input
                    required
                    name="correo_subscripcion"
                    // value={info_form.asunto}
                    // onChange={handleChange}
                    type="mail"
                    autoComplete="new-password"
                    style={{ width: "70%" }}
                  />
                  <label>Correo electrónico</label>
                  <button className="btn-login">SUSCRIBIRSE</button>
                </div>
              </div>
            </form>
          </div>
        </section>
      </div>
    </>
  );
};

export default Contact;
