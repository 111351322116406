import React from "react";
import "styles/Home.css";
import Banners from "./Banners";
import Categories from "./Categories";

const Home = () => {
  return (
    <>
      <Banners />
      <Categories />
    </>
  );
};

export default Home;
