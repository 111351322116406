import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import "styles/navbar.css";
import useSendEventGA from "../hooks/useSendEventGA";

const Navbar = () => {
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    function handleScroll() {
      setIsSticky(window.scrollY > 80);
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const SendEvent = (e) => {
    e.preventDefault();
    window.open("https://vip.grupoalianzacolombia.com", "_blank");
    useSendEventGA("Priority", "Click en boton mi cuenta (Navbar)");
  };

  return (
    <>
      <nav className={"navbar navbar-expand-lg navbar-gac-scroll"}>
        <div className="container navbar-gac">
          <NavLink to="/">
            <img
              loading="lazy"
              src="/images/logo/logo.webp"
              alt="Grupo Alianza Colombia Viajar"
              width={200}
            />
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbar-gac"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbar-gac">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0 list-inline mx-auto justify-content-center">
              <li className="nav-item">
                <NavLink to="/" className="nav-link navlink">
                  Inicio
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/about"
                  className={isSticky ? "nav-link" : "nav-link navlink"}
                >
                  Sobre nosotros
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/blog"
                  className={isSticky ? "nav-link" : "nav-link navlink"}
                >
                  Blog
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/contacto"
                  className={isSticky ? "nav-link" : "nav-link navlink"}
                >
                  Contáctanos
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/questions"
                  className={isSticky ? "nav-link" : "nav-link navlink"}
                >
                  Preguntas frecuentes
                </NavLink>
              </li>

              <li className="nav-item">
                <button className="btn-login" onClick={SendEvent}>
                  Mi cuenta
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
