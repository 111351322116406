import React from "react";
import listQuestions from "assets/Questions.json";

const Questions = () => {
  return (
    <>
      <div className="contenedor-relative">
        <img
          loading="lazy"
          src="/images/PreguntasFrecuentes.png"
          alt=""
          width={"100%"}
        />
        <h1 className="title-section" id="ask">
          PREGUNTAS FRECUENTES
        </h1>
      </div>
      <div className="container mt-5 mb-5">
        <div className="accordion accordion-flush" id="accordionFlushExample">
          {listQuestions.map((ask, index) => (
            <div className="accordion-item" key={index}>
              <h2 className="accordion-header" id={`flush-heading-${index}`}>
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#flush-collapse-${index}`}
                  aria-expanded="false"
                  aria-controls={`flush-collapse-${index}`}
                >
                  {ask.pregunta}
                </button>
              </h2>
              <div
                id={`flush-collapse-${index}`}
                className="accordion-collapse collapse"
                aria-labelledby={`flush-heading-${index}`}
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  <p dangerouslySetInnerHTML={{ __html: ask.respuesta }}></p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Questions;
