import React from "react";
import "styles/footer.css";
import { NavLink } from "react-router-dom";
const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <>
      <section className="centrar">
        <img
          src="/images/seguridad.jpg"
          alt="Grupo alianza colombia informa: No tenemos servicios tercerizados"
          className="img-fluid"
        />
      </section>
      <footer
        className="text-center text-lg-start bg-light text-muted p-1"
        data-aos="fade-in"
      >
        <section className="">
          <div className="container text-center text-md-start mt-5">
            <div className="row mt-3">
              <div className="col-md-4 col-lg-4 col-xl-3 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold mb-4">
                  <img
                    loading="lazy"
                    src="/images/logo/logo.webp"
                    alt="logo"
                    width={200}
                  />
                </h6>
                <p>¡Síguenos en redes!</p>
                <div className="mt-2">
                  <a
                    href="https://www.facebook.com/Grupoalianzacolombia"
                    target="_blank"
                    rel="noreferrer"
                    className="round"
                  >
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  <a
                    href="https://www.instagram.com/grupoalianzacolombia"
                    target="_blank"
                    rel="noreferrer"
                    className="round"
                  >
                    <i className="fa-brands fa-instagram"></i>
                  </a>
                  <a
                    href="https://www.tiktok.com/@grupoalianzacolombia?lang=es"
                    target="_blank"
                    rel="noreferrer"
                    className="round"
                  >
                    <i className="fa-brands fa-tiktok"></i>
                  </a>
                  <a
                    href="https://www.youtube.com/@grupoalianzacolombia"
                    target="_blank"
                    rel="noreferrer"
                    className="round"
                  >
                    <i className="fa-brands fa-youtube"></i>
                  </a>
                </div>
              </div>
              <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold mb-4 text-center">
                  Legales
                </h6>
                <NavLink to="/pqrs" className="text-reset parrafo">
                  <strong>PQRS</strong>
                </NavLink>
                <p>
                  <NavLink to="/sagrilaft" className="text-reset parrafo">
                    <strong>SAGRILAFT Y PTEE</strong>
                  </NavLink>
                </p>
                <p>
                  <NavLink to="/linea-etica" className="text-reset parrafo">
                    <strong>Línea ética</strong>
                  </NavLink>
                </p>
                <p>
                  <a
                    href="/files/Politica-de-tratamiento-de-datos-Personales.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-reset parrafo"
                  >
                    Política de tratamiento de datos personales
                  </a>
                </p>
                <p>
                  <a
                    href="/files/Politica-De-Privacidad-1.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-reset parrafo"
                  >
                    Aviso de privacidad
                  </a>
                </p>
                <p>
                  <NavLink
                    to="/politica-de-ley-de-retracto"
                    className="text-reset parrafo"
                  >
                    Política ley de retracto
                  </NavLink>
                </p>
                <p>
                  <NavLink
                    to="/politica-de-proteccion-de-menores"
                    className="text-reset parrafo"
                  >
                    Política de protección a menores
                  </NavLink>
                </p>
                <p>
                  <NavLink to="/bio-seguridad" className="text-reset parrafo">
                    Bio-seguridad
                  </NavLink>
                </p>
              </div>
              <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold mb-2 text-center">
                  Medios de pago
                </h6>
                <div className="centrar">
                  <a
                    href="https://portalpagos.davivienda.com/#/comercio/6857/GRUPO%20ALIANZA%20COLOMBIA%20SAS"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn-davivienda"
                  >
                    PSE en{" "}
                    <img
                      loading="lazy"
                      src="/images/logo/davivienda.webp"
                      alt="pse davivienda"
                      width={50}
                    />
                  </a>
                </div>

                <NavLink to="/mercadopago" className="centrar">
                  <img
                    loading="lazy"
                    src="/images/logo/logo-mercado-pago.svg"
                    alt="mercado pago"
                    width={180}
                    height={50}
                  />
                </NavLink>
                <div className="centrar">
                  <button
                    className="btn-bancolombia"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    {/* Pagar con Codigo QR de Bancolombia */}
                    <img
                      loading="lazy"
                      src="/images/logo/bancolombia.svg"
                      width={200}
                      height={50}
                      alt="Pagar con Codigo QR de Bancolombia"
                    />
                  </button>
                  <div
                    className="modal fade"
                    id="exampleModal"
                    tabIndex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog  modal-md">
                      <div className="modal-content">
                        <div className="modal-body">
                          <img
                            loading="lazy"
                            src="/files/QR.jpg"
                            alt=""
                            width="100%"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 col-xl-3 mx-auto mb-md-0 mb-4 ">
                <h6 className="text-uppercase fw-bold mb-4 text-center">
                  Contacto
                </h6>
                <p className="text-footer">
                  <i className="fa-solid fa-phone-volume icon"></i>
                  <a href="tel:+6015520100">(601)5520100</a> -
                  <a href="tel:+6012205214">(601)2205214</a>
                </p>
                <p className="text-footer">
                  <i className="fas fa-envelope icon"></i>
                  <a href="mailto:sac@grupoalianzacolombia.com" id="correo">
                    sac@grupoalianzacolombia.com
                  </a>
                </p>
                <p className="text-footer">
                  <i className="fa-solid fa-house icon"></i> Bogotá, Colombia
                </p>
              </div>
            </div>
          </div>
        </section>
        <div className="text-center p-4">
          <p className="rnt">
            RNT N° 127027 Grupo Alianza Colombia SAS. NIT. 901088947-6, Oficina
            Principal Bogotá, Calle 93B # 17-25 Oficina 309-310-311 Edificio
            Centro Empresarial PH. Estamos comprometidos con: Ley 679 de 2001 y
            ley 1336 de 2009. Contra la explotación, la pornografía, el turismo
            sexual y otras formas de abuso a los menores. Decreto 2737 de 1989.
            Por el cual se expide el código del menor y en la cual está en
            contra de la explotación laboral infantil. Ley 397 de 1997. Contra
            el tráfico de Patrimonio Cultural. Ley 599 de 2000, decreto 1608 de
            1978 y Ley 1453 de 2011. Contra el tráfico de especies como flora y
            fauna silvestre. Ley 1335 de 2009. Antitabaco, por un espacio libre
            de humo del tabaco. Ley 1482 de 2011. Garantizar la protección de
            los derechos de una persona, grupo de personas, comunidad o pueblo,
            que son vulnerados a tráficos de actos de racismo o discriminación.
            Damos cumplimiento a lo establecido por la Ley 1581 de 2012, sobre
            protección de datos personales.
          </p>
          © {year} | Todos los derechos reservados. Grupo Alianza Colombia.SAS
        </div>
      </footer>
    </>
  );
};

export default Footer;
